<template>
  <div>
    <Cycles
      id="cycle"
      :first="locale[lang].PERIODISATION.FIRST"
      :images="images"
      :check="locale[lang].PERIODISATION.CHECK"
      :semaine="locale[lang].PERIODISATION.SEMAINE"
      :apres="locale[lang].PERIODISATION.APRES"
      :exercices="locale[lang].PERIODISATION.EXERCICES"
      :infoShop="locale[lang].PERIODISATION.INFOSHOP"
    />
  </div>
</template>

<script>
import Cycles from "@/components/Cycles.vue";
export default {
  components: {
    Cycles,
  },
  data() {
    return {
      images: [
        "Cycles/PERIODISAT43 (2).png",
        "Cycles/PERIODISAT36 (2).png",
        "Cycles/PERIODISAT44 (2).png",
      ],
    };
  },
};
</script>

<style scoped>
#cycle >>> #first p {
  font-weight: 800;
}

#cycle >>> #first p span {
  font-weight: 500;
}

#cycle >>> #first img {
  border-radius: 10px;
}

#cycle >>> #semaine {
  background-size: 100% 72%, auto;
}

@media (min-width: 825.1px) and (max-width: 1024px) {
  #cycle >>> #semaine {
    background-size: 100% 87%;
    padding: 12% 5% 32%;
  }

  #cycle >>> #semaine .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  #cycle >>> #semaine h3 {
    margin-top: 17%;
  }
}

@media (max-width: 825px) {
  #cycle >>> #semaine {
    background-size: 100% 90%;
  }

  #cycle >>> #semaine h3 {
    margin-top: 40%;
  }
}

@media (max-width: 425px) {
  #cycle >>> #first h1 {
    font-size: 16px;
  }
  #cycle >>> #first p {
    font-size: 14px;
  }
  #cycle >>> #semaine {
    background-size: 100% 96%;
  }
}

@media (max-width: 375px) {
  #cycle >>> #semaine {
    background-size: 100% 98%;
  }
}
</style>
